import React from "react"
import styled from "@emotion/styled"

import Layout from "../layouts/index"
import SEO from "../components/seo"
import Background from "../components/background"
import { color } from "../utils"

import bootstrapLogo from "../images/toolboxLogos/bootstrap-logo.svg"
import cssLogo from "../images/toolboxLogos/CSS3-logo.svg"
import djangoLogo from "../images/toolboxLogos/django-logo.svg"
import gatsbyLogo from "../images/toolboxLogos/gatsby-logo.svg"
import gitLogo from "../images/toolboxLogos/git-logo.svg"
import gsapLogo from "../images/toolboxLogos/gsap-logo.png"
import htmlLogo from "../images/toolboxLogos/HTML5-logo.svg"
import javascriptLogo from "../images/toolboxLogos/javascript-logo.svg"
import jqueryLogo from "../images/toolboxLogos/jquery-logo.svg"
import mysqlLogo from "../images/toolboxLogos/mysql-logo.svg"
import pythonLogo from "../images/toolboxLogos/python-logo.svg"
import rLogo from "../images/toolboxLogos/r-logo.svg"
import reactLogo from "../images/toolboxLogos/react-logo.svg"
import shinyLogo from "../images/toolboxLogos/shiny-logo.png"

const ToolboxWrapper = styled.article`
  position: relative;
  z-index: 2;
  margin: 0;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  font-size: 0.9rem;
  opacity: 0;
  background-color: ${color.backgroundTransparencyBlack};
  padding: 2rem;

  animation: fade 1.5s ease-in-out forwards;
  @keyframes fade {
    to {
      opacity: 1;
    }
  }

  @media screen and (min-width: 500px) {
    margin: 2% 8%;
    grid-template-columns: repeat(2, 1fr);
    .title {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 2;
    }
  }

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
    .title {
      grid-column-end: 4;
    }
  }

  @media screen and (min-width: 1000px) {
    grid-template-columns: repeat(4, 1fr);
    .title {
      grid-column-end: 5;
    }
  }

  @media screen and (min-width: 1300px) {
    margin: 3% 10%;
    grid-template-columns: repeat(5, 1fr);
    .title {
      grid-column-end: 6;
    }
  }

  @media screen and (max-width: 768px) and (orientation: landscape),
    (orientation: portrait) {
    margin: 0;
  }
`
const FirstLine = styled.p`
  margin: 0;
  padding: 20% 10%;
  background-color: ${color.backgroundTransparencyWhite};
  font-size: 1.2rem;
`
const Tool = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: ${color.backgroundTransparencyWhite};

  p {
    padding: 4px 12px;
  }
`
const Logo = styled.div`
  width: 100%;
  background-color: ${color.textBackgroundBlack};
  margin: 0 0 0.5rem 0;
  padding: 5%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;

  img {
    height: 40px;
    margin: 0;
  }
  span {
    color: #61dafb;
    font-size: 1.6rem;
    margin-left: -5px;
  }

  @media screen and (min-width: 500px) {
    padding: 15%;
  }
`
const Text = styled.div`
  height: 100%;

  span {
    font-weight: bold;
  }

  :hover {
    color: ${color.primaryHoverColor};
    transition: color 0.3s;
  }
`
const Title = styled.h1`
  &.title {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0 0 0;
    color: ${color.backgroundTransparencyWhite};
    font-weight: 900;
  }

  @media screen and (min-width: 768px) {
    &.title {
      font-size: 2.5rem;
    }
  }
`

const Toolbox = () => (
  <Layout>
    <SEO title="Toolbox" />
    <Background opacity="low" />
    <ToolboxWrapper>
      <Title className="title">Toolbox</Title>
      <FirstLine>
        Here are some tools I like to work with and have been using in my
        projects.
      </FirstLine>
      <Tool>
        <Logo>
          <img src={htmlLogo} alt="HTML Logo" title="HTML5" />
        </Logo>
        <Text>
          <p>
            <span>HTML:</span> The basis for laying out the web.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={cssLogo} alt="CSS3 Logo" title="CSS3" />
        </Logo>
        <Text>
          <p>
            <span>CSS:</span> The basis for styling the web.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={javascriptLogo} alt="JavaScript Logo" title="JavaScript" />
        </Logo>
        <Text>
          <p>
            <span>JavaScript</span>&#58; Best programming language around!
            (sorry to all the others...)
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={reactLogo} alt="React Logo" title="ReactJS" />
          <span title="ReactJS">React</span>
        </Logo>
        <Text>
          <p>
            <span>React:</span> This library completely changed the way I code.
            Can't recommend it enough.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={gatsbyLogo} alt="Gatsby Logo" title="GatsbyJS" />
        </Logo>
        <Text>
          <p>
            <span>Gatsby:</span> Awesome React-based framework! (this site was
            built with Gatsby 😊)
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={pythonLogo} alt="Python Logo" title="Python" />
        </Logo>
        <Text>
          <p>
            <span>Python:</span> Has the most beautiful syntax! I love using it
            for server side coding.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={djangoLogo} alt="Django Logo" title="Django" />
        </Logo>
        <Text>
          <p>
            <span>Django:</span> Great framework to create web applications.
            Very good security features.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={mysqlLogo} alt="MySQL Logo" title="MySQL" />
        </Logo>
        <Text>
          <p>
            <span>MySQL:</span> Database system that uses SQL. Great for
            projects that use relational databases.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={gitLogo} alt="Git Logo" title="Git" />
        </Logo>
        <Text>
          <p>
            <span>Git:</span> I use it for version control, in conjunction with
            GitLab and GitHub.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={rLogo} alt="R Logo" title="R" />
        </Logo>
        <Text>
          <p>
            <span>R:</span> Very good language for treatment of scientific and
            statistical data.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={shinyLogo} alt="R Shiny Logo" title="R Shiny" />
        </Logo>
        <Text>
          <p>
            <span>R Shiny:</span> This is a great R package designed to create
            interactive web apps.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={jqueryLogo} alt="jQuery Logo" title="jQuery" />
        </Logo>
        <Text>
          <p>
            <span>jQuery:</span> Great JS library for DOM manipulation and event
            handling.
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={gsapLogo} alt="Greensock Logo" title="GatsbyJS" />
        </Logo>
        <Text>
          <p>
            <span>Greensock/GSAP:</span> Super amazing tool to create animations
            for the web!
          </p>
        </Text>
      </Tool>
      <Tool>
        <Logo>
          <img src={bootstrapLogo} alt="Bootstrap Logo" title="Bootstrap" />
        </Logo>
        <Text>
          <p>
            <span>Bootstrap:</span> Very complete HTML, CSS, JS framework, but a
            bit too standard and rigid.
          </p>
        </Text>
      </Tool>
    </ToolboxWrapper>
  </Layout>
)

export default Toolbox
